<template>
  <div :class="'storeAppModal storeAppModal-open'">
    <div
      class="storeAppModal__close"
      @click="close"
    />
    <div class="storeAppModal__content">
      <div class="sonikStore__image" />
      <div
        class="storeAppModal__close__content"
        @click="close"
      />
      <div class="storeAppModal__content__margins">
        <div
          v-if="section == 'checkout'"
          class="appCheckoutSonikStore"
        >
          <div class="back">
            <button
              type="button"
              class="backButton"
              @click="back"
            >
              Back
            </button>
          </div>
          <div class="appCheckoutSonikStore__header">
            Choose where will this toy arrives
          </div>
          <div class="appCheckoutSonikStore__container">
            <img
              :src="resolveImage(selectedProduct.image)"
              class="appCheckoutSonikStore__container__image"
            >
            <div class="appCheckoutSonikStore__container__details">
              <div class="appCheckoutSonikStore__container__details__title">
                {{ selectedProduct.name }}
              </div>
              <div
                class="appCheckoutSonikStore__container__details__description"
              >
                {{ selectedProduct.description }}
              </div>
              <div class="appCheckoutSonikStore__container__details__points">
                {{ selectedProduct.points }} points
              </div>
            </div>
          </div>
          <div style="display: none">
            {{ selectedAddressId }}
          </div>
          <div class="appCheckoutSonikStore__center">
            <img
              src="../assets/images/arrow_down.png"
              class="appCheckoutSonikStore__center__arrow"
            >
          </div>
          <div
            v-for="address in getDeliveryAddresses"
            :key="address.id"
            class="appCheckoutSonikStore__address"
            :class="{
              appCheckoutSonikStore__address__selected:
                address.id == selectedAddressId,
            }"
            @click="selectAddress(address.id)"
          >
            <!-- <img
              :src="resolveImage(address.imageLink)"
              class="appCheckoutSonikStore__address__image"
            /> -->

            <div v-if="address.type == 'Home'">
              <img
                src="../assets/images/address_home.png"
                class="appProfileAdress__house"
                :alt="address.type"
              >
            </div>
            <div v-else-if="address.type == 'Work'">
              <img
                src="../assets/images/address_work.png"
                class="appProfileAdress__house"
                :alt="address.type"
              >
            </div>
            <div v-else-if="address.type == 'Grandma'">
              <img
                src="../assets/images/address_grandma.png"
                class="appProfileAdress__house"
                :alt="address.type"
              >
            </div>
            <div v-else-if="address.type == 'Grandpa'">
              <img
                src="../assets/images/address_grandpa.png"
                class="appProfileAdress__house"
                :alt="address.type"
              >
            </div>
            <div v-else-if="address.type == 'Other'">
              <img
                src="../assets/images/address_other.png"
                class="appProfileAdress__house"
                :alt="address.type"
              >
            </div>

            <div class="appCheckoutSonikStore__address__content">
              <div class="appCheckoutSonikStore__address__details">
                <div class="appCheckoutSonikStore__address__details__name">
                  {{ address.type }}
                </div>
                <div
                  class="appCheckoutSonikStore__address__details__description"
                >
                  {{ address.address }}
                </div>
              </div>
            </div>
          </div>
          <AppErrorMessage :message="error" />
          <AppButton
            text="Send order"
            :color="buttonColor"
            :mini="true"
            class="appCheckoutSonikStore__button"
            @click="addOrder"
          />
        </div>
        <div
          v-else
          class="sonikStore"
        >
          <div class="sonikStore__container">
            <div class="sonikStore__container__points">
              <div class="sonikStore__container__points__number">
                {{ points }} points
              </div>
            </div>
            <div class="sonikStore__container__products row">
              <AppSonikStoreItem
                v-for="product in getSecretStoreProducts"
                :id="product.id"
                :key="product.id"
                :name="product.name"
                :description="product.description"
                :points="product.price"
                :image="product.default_picture_link"
                class="skGrid"
                @checkoutProduct="selectProduct"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from "../components/AppButton";
import AppErrorMessage from "../components/AppErrorMessage";
import AppSonikStoreItem from "../components/AppSonikStoreItem";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import config from "../config";

export default {
  components: {
    AppSonikStoreItem,
    AppButton,
    AppErrorMessage,
  },
  props: {
    points: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters(["getSecretStoreProducts", "getDeliveryAddresses"]),
  },
  data() {
    return {
      section: "products",
      buttonColor: "",
      selectedAddressId: null,
      selectedProduct: {
        id: null,
        name: "",
        description: "",
        quantity: 1,
        delivery_address_id: null,
        image: "",
      },
      error: "",
    };
  },
  // mounted() {
  //   console.log(this.getSecretStoreProducts);
  //   console.log(this.points);
  // },
  methods: {
    ...mapActions(["addSecretStoreOrder"]),
    resolveImage(src) {
      return config.HOST + src;
    },
    close() {
      this.$emit("close");
    },
    back() {
      this.section = "products";
      this.selectedAddressId = null;
    },
    selectProduct(product) {
      this.section = "checkout";
      this.selectedProduct = {
        id: product.id,
        name: product.name,
        description: product.description,
        quantity: 1,
        image: product.image,
        points: product.points,
      };
    },
    selectAddress(id) {
      this.selectedAddressId = id;
      this.buttonColor = "blue";
    },
    addOrder() {
      if (this.buttonColor != "") {
        this.selectedProduct.address_id = this.selectedAddressId;
        this.addSecretStoreOrder(this.selectedProduct);
        this.buttonColor = "";
        this.selectedProduct = {
          id: null,
          name: "",
          description: "",
          quantity: null,
          image: "",
        };
        this.section = "products";
        this.selectedAddressId = null;
        this.close();
      } else {
        this.error = "Please select an order.";
      }
    },
  },
};
</script>

<style lang="scss">
.appCheckoutSonikStore__address__selected {
  background: #36d5f2;
  border: 2px solid #36d5f2 !important;
  color: white;
  .appCheckoutSonikStore__address__details__name,
  .appCheckoutSonikStore__address__details__description {
    color: white;
  }
  .appCheckoutSonikStore__address__details__name {
    font-size: 23px;
  }
}
.sonikStore {
  // width: 100%;

  &__image {
    background-image: url("../assets/images/sonikStoreBg.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 50%;
    width: 100%;
    background-position: center;
    background-size: cover;
  }
  &__container {
    width: auto;
    margin-top: 10px;
    margin-bottom: 40px;
    margin-left: 30px;
    margin-right: 30px;

    &__points {
      width: 300px;
      height: 50px;
      background: linear-gradient(180deg, #00edff 0%, rgba(0, 237, 255, 0) 100%),
        #3ec6ff;
      border-radius: 40px;
      margin: auto;
      margin-top: -35px;

      &__number {
        text-align: center;
        padding-top: 10px;

        font-size: 20px;
        line-height: 30px;
        color: #ffffff;
      }
    }

    &__products {
      margin-top: 20px;
      width: auto;
      display: grid;
      grid-gap: 30px;
      grid-template-columns: 1fr 1fr;
    }
  }
}

.storeAppModal {
  overflow-x: none;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  min-height: 10vh;
  overflow: auto;
  background-color: rgba(black, 0.2);
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__content {
    max-height: 80vh;
    width: 770px;
    background-color: white;
    border-radius: 50px;
    // padding: 70px !important;
    box-sizing: border-box;
    overflow: auto;
    // padding-left: 40px;
    // padding-right: 40px;
  }

  &__close,
  &__close__content {
    position: relative;
    top: 20px;
    // left: 0;
    width: 40px;
    height: 40px;
    border-radius: 12px;
    background-color: #ff4f79;
    background-image: url("../assets/images/cancel.png");
    background-repeat: no-repeat;
    background-size: 26px;
    background-position: center;
    z-index: 10;
    cursor: pointer;

    &__content {
      display: none;
    }
  }

  &-open {
    display: flex;
    z-index: 4;
  }
}
.storeAppModal__content::-webkit-scrollbar {
  border-radius: 50px;
  width: 10px;
  background-color: transparent;
  left: -20px;
}

.storeAppModal__content::-webkit-scrollbar-thumb {
  background-color: #e5e5e5;
  border: 2px solid #e5e5e5;
  border-radius: 100px;
}

@media screen and (max-width: 850px) {
  .sonikStore__container__points {
    margin-top: 40px;
    width: 100%;
  }
  .sonikStore__container__products {
    margin-top: 20px;
    width: auto;
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr;
  }
  .sonikStore__image {
    display: none;
  }
  .storeAppModal__content::-webkit-scrollbar {
    display: none;
  }

  .storeAppModal {
    z-index: 5;

    &__content {
      max-height: 100vh;
      width: 100%;
      height: 100vh;
      border-radius: 0px;

      &__margins {
        padding: 0px;
      }
    }

    &__close {
      display: none;

      &__content {
        display: block;
        // top: 10px;
        // left: 50%;
        transform: translateX(-20px);
      }
    }
  }
}

@media screen and (max-height: 650px) {
  .storeAppModal {
    &__content {
      overflow-y: scroll;
    }
  }
}
.appCheckoutSonikStore {
  width: auto;
  padding: 40px;

  &__game {
    width: 100%;

    &__title {
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 40px;
      line-height: 40px;
      color: #7a615a;
      margin-top: 50px;
      width: 100%;
    }

    &__wallets {
      margin-top: 30px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 30px;

      &__item {
        height: 100%;
        width: 100%;
      }
    }

    &__input {
      width: 100% !important;
    }

    &__actions {
      display: flex;
      align-items: center;
      justify-content: center;

      &__button {
        margin-top: 40px;
        width: 50%;
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 20px;
    line-height: 21px;
    color: #ff4f79;
    margin: auto;
    margin-bottom: 40px;
  }

  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr;

    &__image {
      width: 100%;
    }

    &__details {
      width: 100%;
      margin-top: 50px;

      &__title {
        font-size: 30px;
        line-height: 21px;
        color: #7a615a;
        padding: 10px;
        margin-left: 20px;
      }
      &__description {
        font-size: 25px;
        line-height: 21px;
        color: #7a615a;
        padding: 10px;
        margin-left: 20px;
      }
      &__points {
        font-size: 30px;
        line-height: 21px;
        color: #36d5f2;
        padding: 10px;
        margin-left: 20px;
      }
    }
  }

  &__center {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 20px;

    &__arrow {
      width: 30px;
    }
  }

  &__address {
    width: 100%;
    min-height: 100px;
    max-height: 300px;
    border: 2px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 20px;
    display: flex;
    align-items: center;
    // display: grid;
    // grid-template-columns: 1fr 9fr;
    margin-bottom: 20px;

    &__image {
      padding: 20px;
    }

    &__details {
      width: 100%;
      padding: 20px;

      &__name {
        font-size: 23px;
        line-height: 25px;
        color: #36d5f2;
      }

      &__description {
        font-size: 16px;
        line-height: 21px;
        color: #7a615a;
        margin-top: 10px;
        margin-right: 20px;
      }
    }
  }

  // &__button {
  //   margin-left: 30px;
  //   margin-right: 30px;
  // }

  &__receipt {
    width: auto;
    padding: 10px;
    background: #ffffff;
    // border-radius: 20px;

    &__top {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      padding: 20px;
      background: #ffffff;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
      border-bottom: dotted #e5e5e5;
    }

    &__content {
      border-radius: 20px;
      padding: 20px;
      background: #ffffff;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);

      &__item {
        width: 100%;
        padding: 20px;
        display: grid;
        grid-template-columns: 10fr 3fr;

        &__name {
          font-size: 18px;
          line-height: 21px;
          color: #7a615a;
        }

        &__price {
          font-size: 20px;
          line-height: 25px;
          color: #7a615a;
          margin-right: 20px;
        }
      }

      &__line {
        width: 100%;
        height: 3px;
        background-color: #e5e5e5;
      }
    }
  }
}
@media screen and (max-width: 850px) {
  .appCheckoutSonikStore__address {
    margin-left: -35px;
    margin-right: -30px;
    width: auto;
  }
  .appCheckoutSonikStore__address__image {
    height: 100px;
    width: 100px;
    margin-top: 10px;
  }
  .appCheckoutSonikStore__address__details {
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
  }
  .appCheckoutSonikStore__game__wallets {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }
  .appCheckoutSonikStore__game__actions__button {
    width: 100%;
  }
  .back {
    margin-top: 50px;
  }
}
.back {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.backButton {
  width: 50% !important;
  box-sizing: border-box;
  padding: 0px 30px;
  height: 50px;
  min-width: 20px;
  outline: none;
  border: none;
  border-radius: 74px;
  background-color: #e5e5e5;
  color: white;
  margin-top: -65px;
  cursor: pointer;
  font: {
    size: 18px;
  }
  background-color: #36d5f2;
  box-shadow: 0px 4px 20px rgba(54, 213, 242, 0.5);
  margin-bottom: 20px;
}

.appProfileAdress__house {
  max-width: 50px;
  max-height: 50px;
  margin: 0;
  margin-right: 10px;
  margin-left: 20px;
}
</style>
