<template>
  <div class="sonikStoreItem">
    <img
      :src="resolveImage(image)"
      class="sonikStoreItem__img"
    >
    <div>
      <div class="sonikStoreItem__title">
        {{ name }}
      </div>
      <div class="sonikStoreItem__action">
        {{ points }} points
        <button
          v-if="points <= getSecretStorePoints"
          type="button"
          class="sonikStoreItem__button"
          @click="selectProduct"
        >
          Buy
        </button>
        <button
          v-else
          type="button"
          class="sonikStoreItem__inactive"
        >
          Buy
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import config from "../config";

export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    points: {
      // type: Number,
      // default: 0
    },
    image: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters(["getSecretStorePoints"]),
  },
  methods: {
    resolveImage(src) {
      return config.HOST + src;
    },
    selectProduct() {
      let product = {
        id: this.id,
        name: this.name,
        description: this.description,
        image: this.image,
        points: this.points,
      };
      this.$emit("checkoutProduct", product);
    },
  },
};
</script>

<style lang="scss">
.sonikStoreItem {
  width: 100%;
  // display: grid;
  //   grid-template-columns: 1fr 1fr;
  border: 2px dashed #e5e5e5;
  border-radius: 40px;
  min-height: 150px;
  padding: 10px;

  &__img {
    margin: auto;
    max-width: 130px;
    max-height: 130px;
    width: auto;
    height: auto;
  }

  &__title {
    font-size: 18px;
    line-height: 21px;
    color: #7a615a;
    margin-top: 30px;
  }

  &__action {
    margin-top: 10px;

    font-size: 20px;
    line-height: 24px;
    color: #7a615a;
    margin-bottom: 0px;
  }

  &__button {
    width: 100%;
    box-sizing: border-box;
    padding: 0px 30px;
    height: 40px;
    min-width: 20px;
    outline: none;
    border: none;
    border-radius: 74px;
    background-color: #e5e5e5;
    color: white;
    margin-top: 20px;
    cursor: pointer;
    font: {
      size: 18px;
    }
    background-color: #36d5f2;
    box-shadow: 0px 4px 20px rgba(54, 213, 242, 0.5);
  }
  &__inactive {
    width: 100%;
    box-sizing: border-box;
    padding: 0px 30px;
    height: 40px;
    min-width: 20px;
    outline: none;
    border: none;
    border-radius: 74px;
    background-color: #e5e5e5;
    color: white;
    margin-top: 20px;
    cursor: pointer;
    font: {
      size: 18px;
    }
    background-color: #dddddd;
  }
}
</style>
